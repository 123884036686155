<template>
  <b-modal
    title="Cargar turnos"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row class="mt-3">
      <b-col>
        <input
          type="file"
          class="form-control form-control-sm"
          accept=".xlsx, .xls"
          @change="handleDrop($event.target.files)"
        />
      </b-col>
    </b-row>
    <div
      class="separator separator-dashed mt-3 mb-3"
      v-if="importData.data && importData.data.length"
    ></div>
    <transition name="fade">
      <b-row v-if="importData.data && importData.data.length">
        <b-col>
          <b-col class="dx-viewport mt-3">
            <DxDataGrid
              :data-source="importData.data"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="true"
              :columnAutoWidth="true"
              :allow-column-resizing="true"
              @selection-changed="onSelectionChanged"
            >
              <DxSelection mode="multiple" v-if="false" />
              <DxExport :enabled="false" />
              <DxGroupPanel
                :visible="false"
                emptyPanelText="Arrastre una columna aquí para agrupar"
              />
              <DxSearchPanel
                :visible="true"
                :width="240"
                placeholder="Buscar..."
              />
              <DxPaging :page-size="15" />
              <DxPager
                :visible="true"
                infoText="Página {0} de {1} ({2} registros)"
                :allowed-page-sizes="[10, 20, 30, 50]"
                display-mode="full"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
              />
              <DxHeaderFilter :visible="true" />
              <DxFilterRow :visible="true">
                <DxOperationDescriptions
                  equal="Igual a"
                  notEqual="Diferente de"
                  startsWith="Empieza con"
                  endsWith="Termina con"
                  contains="Contiene"
                  between="Entre"
                  notContains="No contiene"
                  lessThanOrEqual="Menor o igual"
                  greaterThanOrEqual="Mayor o igual"
                  lessThan="Menor que"
                  greaterThan="Mayor que"
                />
              </DxFilterRow>
              <DxColumn data-field="cod" caption="Código" width="130" />
              <DxColumn
                data-field="jornada"
                caption="Jornada"
                width="130"
                alignment="center"
              />
              <DxColumn data-field="descripcion" caption="Turno" />
              <DxColumn data-field="fecha" caption="Fecha inicio" />
              <DxColumn data-field="fecha_fin" caption="Fecha fin" />
            </DxDataGrid>
          </b-col>
        </b-col>
      </b-row>
    </transition>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="loadMassiveTurn()">
          <i class="la la-save"></i>
          {{ loading ? "Cargando..." : "Cargar turno" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxColumn,
  DxSelection,
} from "devextreme-vue/data-grid";
import * as XLSX from "xlsx/xlsx.mjs";
import toastr from "toastr";
import axios from "axios";
import { mapGetters } from "vuex";
const fixdata = (data) => {
  var o = "";
  var l = 0;
  var w = 10240;
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w))
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
};
const get_header_row = (sheet) => {
  var headers = [];
  var range = XLSX.utils.decode_range(sheet["!ref"]);
  var C;
  var R = range.s.r; /* start in the first row */
  for (C = range.s.c; C <= range.e.c; ++C) {
    /* walk every column in the range */
    var cell =
      sheet[
        XLSX.utils.encode_cell({ c: C, r: R })
      ]; /* find the cell in the first row */
    var hdr = "XXX"; // <-- replace with your desired default
    if (cell && cell.t) {
      hdr = XLSX.utils.format_cell(cell);
    } else {
      continue;
    }
    let column = {
      field: hdr,
      name: hdr,
      label: hdr,
    };
    headers.push(column);
  }
  return headers;
};
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxColumn,
    DxSelection,
  },
  data() {
    return {
      loading: false,
      show: false,
      importData: {
        headers: [],
        data: [],
      },
      data: [],
      selectedRowsData: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    init() {
      this.show = true;
      this.importData = {
        headers: [],
        data: [],
      };
      this.data = [];
      this.selectedRowsData = [];
      this.loading = false;
    },
    handleDrop(fileList) {
      var f = fileList[0];
      var reader = new FileReader(),
        name = f.name;
      console.log(name);
      reader.onload = (e) => {
        var results,
          data = e.target.result,
          fixedData = fixdata(data),
          workbook = XLSX.read(btoa(fixedData), { type: "base64" }),
          firstSheetName = workbook.SheetNames[0],
          worksheet = workbook.Sheets[firstSheetName];
        this.importData.headers = get_header_row(worksheet);
        results = XLSX.utils.sheet_to_json(worksheet);
        this.importData.data = results;
      };
      reader.readAsArrayBuffer(f);
    },
    loadMassiveTurn() {
      if (this.importData.data.length) {
        var data = JSON.stringify(this.importData.data);
        this.data = JSON.parse(data);
        this.data.map((el) => {
          el.descripcion = el.descripcion
            .split(" y ")
            .join("-")
            .split(" a ")
            .join("|");
        });
        this.loading = true;
        axios
          .post("/turns", {
            data: this.data,
            user: this.user.usuario,
          })
          .then((res) => {
            if (res.data.message == "success") {
              this.loading = false;
              toastr.success(res.data.information);
              this.$emit("loadTurns", true);
              this.show = false;
            } else {
              this.$swal.fire({
                text: res.data.information,
                icon: "warning",
              });
              this.loading = false;
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        toastr.info("Debe cargar la información");
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
  },
};
</script>
