<template>
  <b-row class="mt-5">
    <b-col>
      <b-card title="Turnos">
        <transition name="bounce">
          <b-row v-if="contratas.length">
            <b-col md="3" class="mb-3">
              <label>Filtrar por contratista</label>
              <select
                class="form-control form-control-sm"
                v-model="contrataSelected"
              >
                <option :value="-1">Todo</option>
                <option :value="con" v-for="(con, idx) in contratas" :key="idx">
                  {{ con }}
                </option>
              </select>
            </b-col>
            <b-col md="3" class="mb-3">
              <label>Filtrar por estado</label>
              <select class="form-control form-control-sm" v-model="status">
                <option :value="-1">Todo</option>
                <option
                  :value="item.id"
                  v-for="(item, idx) in estados"
                  :key="`${idx}_ST`"
                >
                  {{ item.estado }}
                </option>
              </select>
            </b-col>
          </b-row>
        </transition>
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex">
            <div>
              <span
                class="switch switch-sm switch-primary switch-icon switch-primary mr-3"
              >
                <label>
                  <input
                    type="checkbox"
                    checked="checked"
                    v-model="view"
                    v-if="
                      user.roles.name.toLowerCase() == 'superadmin' ||
                      user.roles.name.toLowerCase() == 'administrador'
                    "
                  />
                  <span></span>
                </label>
              </span>
            </div>
            <button
              @click="loadTurns()"
              class="btn btn-sm btn-circle btn-outline-primary mb-2 mr-3"
            >
              <i class="flaticon-refresh"></i>
            </button>
            <button
              class="btn btn-sm btn-circle btn-primary mb-2"
              v-if="can('create-turn')"
              @click="
                () => {
                  $refs.CRT.init();
                }
              "
            >
              <i class="flaticon-calendar-with-a-clock-time-tools"></i> Agregar
            </button>
            <transition name="slide-fade">
              <button
                class="btn btn-sm btn-circle btn-success mb-2 ml-3"
                v-if="can('approve-turn') && selectedRowsData.length && view"
                @click="approbation(2)"
              >
                <i class="flaticon2-notepad"></i> Aprobar
              </button>
            </transition>

            <transition name="slide-fade">
              <button
                class="btn btn-sm btn-circle btn-warning mb-2 ml-3"
                v-if="
                  can('not-approve-turn') && selectedRowsData.length && view
                "
                @click="approbation(3)"
              >
                <i class="flaticon2-notepad"></i> No Aprobar
              </button>
            </transition>

            <transition name="slide-fade">
              <!-- can('approve-turn') && selectedRowsData.length && view -->
              <button
                class="btn btn-sm btn-circle btn-danger mb-2 ml-3"
                v-if="false"
                @click="deleteChosesTurns(selectedRowsData)"
              >
                <i class="flaticon2-trash"></i> Eliminar
              </button>
            </transition>
          </div>
          <div class="d-flex">
            <button
              class="btn btn-sm btn-outline-success btn-circle mb-2"
              v-if="can('create-turn-massive')"
              @click="
                () => {
                  $refs.LTN.init();
                }
              "
            >
              <i class="flaticon2-delivery-package"></i> Cargar turnos
            </button>
            <a
              v-if="can('create-turn-massive')"
              class="btn btn-sm btn-primary mb-2 ml-3"
              href="assets/templates/TURNOS PROGRAMADOS.xls"
              download
            >
              <i class="flaticon-multimedia-4"></i> Descargar Plantilla
            </a>
          </div>
        </div>
        <transition name="fade">
          <b-row v-if="items.length">
            <transition name="slide-fade">
              <b-col v-if="can('calendar-approve') && !view" md="12">
                <FullCalendar :options="calendarOptions" />
              </b-col>
            </transition>
            <transition name="slide-fade">
              <b-col v-if="view" class="dx-viewport mt-3" md="12">
                <div class="separator separator-dashed mt-3 mb-3"></div>
                <DxDataGrid
                  id="TurnsTable"
                  :data-source="turns"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  :allow-column-resizing="true"
                  @editor-preparing="onEditorPreparing"
                  @selection-changed="onSelectionChanged"
                >
                  <DxExport :enabled="true" />
                  <DxSelection
                    v-if="can('approve-turn')"
                    :select-all-mode="allMode"
                    :show-check-boxes-mode="checkBoxesMode"
                    mode="multiple"
                  />
                  <DxGroupPanel
                    :visible="true"
                    emptyPanelText="Arrastre una columna aquí para agrupar"
                  />
                  <DxSearchPanel
                    :visible="true"
                    :width="240"
                    placeholder="Buscar..."
                  />
                  <DxPaging :page-size="10" />
                  <DxPager
                    :visible="true"
                    infoText="Página {0} de {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true">
                    <DxOperationDescriptions
                      equal="Igual a"
                      notEqual="Diferente de"
                      startsWith="Empieza con"
                      endsWith="Termina con"
                      contains="Contiene"
                      between="Entre"
                      notContains="No contiene"
                      lessThanOrEqual="Menor o igual"
                      greaterThanOrEqual="Mayor o igual"
                      lessThan="Menor que"
                      greaterThan="Mayor que"
                    />
                  </DxFilterRow>
                  <DxColumn
                    data-field="codigo"
                    caption="Código"
                    alignment="center"
                    width="130"
                  />
                  <DxColumn
                    data-field="placa"
                    caption="Recurso"
                    alignment="center"
                    width="130"
                    :visible="false"
                  />

                  <DxColumn
                    data-field="status"
                    caption="Estado"
                    alignment="center"
                    width="130"
                  />
                  <DxColumn
                    data-field="observacion"
                    caption="Turno"
                    alignment="center"
                    width="150"
                    cell-template="templateTurn"
                  />

                  <DxColumn
                    data-field="fecha"
                    caption="Fecha inicio turno"
                    data-type="date"
                    width="150"
                    format="dd/MM/yyyy, HH:mm:ss"
                  />

                  <DxColumn
                    data-field="fecha_fin"
                    caption="Fecha fin turno"
                    data-type="date"
                    width="150"
                    format="dd/MM/yyyy, HH:mm:ss"
                  />
                  <DxColumn
                    data-field="jornada.nombre"
                    caption="Jornada"
                    alignment="center"
                    width="130"
                  />
                  <DxColumn
                    data-field="contrata"
                    caption="Contratista"
                    alignment="center"
                  />
                  <DxColumn
                    caption=""
                    width="150"
                    cell-template="cellTemplate"
                  />
                  <template #templateTurn="{ data: cellData }">
                    <div>
                      {{ cellData.data.observacion | performanceTurn }}
                    </div>
                  </template>
                  <template #cellTemplate="{ data: cellData }">
                    <div class="text-center">
                      <router-link
                        :to="{
                          name: 'turnUpdate',
                          params: { id: cellData.data.id },
                        }"
                        v-if="
                          can('update-turn') &&
                          (cellData.data.estado.id == 1 ||
                            user.roles.name == 'SuperAdmin')
                        "
                        class="btn btn-sm btn-circle action"
                      >
                        <i class="flaticon2-pen text-success"></i>
                      </router-link>
                      <button
                        class="btn btn-sm btn-circle action"
                        @click="deleteTurn(cellData.data.id)"
                        v-if="can('delete-turn')"
                      >
                        <i class="flaticon2-rubbish-bin text-danger"></i>
                      </button>
                    </div>
                  </template>
                </DxDataGrid>
              </b-col>
            </transition>
          </b-row>
          <b-row v-else>
            <b-col>
              <div v-if="!loading">
                <div class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay información para mostrar</p>
                </div>
              </div>
              <div class="text-center" v-else>
                <img src="assets/img/loader.gif" alt="Loading..." />
              </div>
            </b-col>
          </b-row>
        </transition>
      </b-card>
    </b-col>
    <LoadTurn ref="LTN" @loadTurns="loadTurns()" />
    <CreateTurn ref="CRT" @loadTurns="loadTurns()" />
    <Approvals ref="APPR" @loadTurns="loadTurns()" />
  </b-row>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}

#TurnsTable {
  max-height: 700px !important;
}
.fc-toolbar-title {
  font-size: 1.3em !important;
}
.fc-event {
  cursor: pointer !important;
}
.fc-daygrid-day-bottom {
  color: #028bba !important;
  font-weight: bold;
}
.fc .fc-more-popover .fc-popover-body {
  max-height: 400px !important;
  overflow-y: auto !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxSelection,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import axios from "axios";
import can from "@/permission";
import LoadTurn from "./LoadTurn.vue";
import { mapGetters } from "vuex";
import toastr from "toastr";
import CreateTurn from "./CreateTurn.vue";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import Approvals from "./Approvals.vue";
import _ from "lodash";

let selectAllCheckBox;
let checkBoxUpdating = false;

export default {
  name: "user-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxSelection,
    LoadTurn,
    CreateTurn,
    FullCalendar,
    Approvals,
  },

  data() {
    return {
      view: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        timeZone: "local",
        eventClick: this.handleDateClick,
        showNonCurrentDates: false,
        dayMaxEventRows: true,
        views: {
          timeGridDay: {
            dayMaxEventRows: 2,
          },
          timeGridWeek: {
            dayMaxEventRows: 2,
          },
          dayGridMonth: {
            dayMaxEventRows: 2,
          },
        },
        events: [],
        weekends: true,
        locale: esLocale,
        headerToolbar: {
          left: "prev next",
          center: "title",
          right: "today timeGridWeek dayGridMonth",
        },
        selectable: true,
        height: 650,
        // número de semanas
        weekNumbers: true,
        nowIndicator: true,
      },

      allMode: "allPages",
      checkBoxesMode: "always",
      // : 'always',
      selection: true,
      items: [],
      contratas: [],
      contrataSelected: -1,
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
      selectedRowsData: [],
      prefix: "",
      estados: [],
      status: -1,
      allCalendar: [],
    };
  },
  mounted() {
    this.loadTurns();
    if (this.user.roles.name.toLowerCase() == "contratista") {
      this.view = true;
    }
  },
  watch: {
    status() {
      this.calendarOptions.events = this.turnsCalendar;
    },
    contrataSelected() {
      this.calendarOptions.events = this.turnsCalendar;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    turns() {
      if (this.contrataSelected == -1) {
        if (this.status == -1) {
          return this.items;
        } else {
          return this.items.filter((f) => f.estado_id == this.status);
        }
      } else {
        if (this.status == -1) {
          return this.items.filter((f) => f.contrata == this.contrataSelected);
        } else {
          return this.items.filter(
            (f) =>
              f.estado_id == this.status && f.contrata == this.contrataSelected
          );
        }
      }
    },
    turnsCalendar() {
      if (this.contrataSelected == -1) {
        if (this.status == -1) {
          return this.allCalendar;
        } else {
          return this.allCalendar.filter((f) => f.estado == this.status);
        }
      } else {
        if (this.status == -1) {
          return this.allCalendar.filter(
            (f) => f.contrata == this.contrataSelected
          );
        } else {
          return this.allCalendar.filter(
            (f) =>
              f.estado == this.status && f.contrata == this.contrataSelected
          );
        }
      }
    },
  },
  filters: {
    performanceTurn(values) {
      var response = values.split("-").join(" y ").split("|").join(" a ");
      return response;
    },
  },
  methods: {
    can: can,
    handleDateClick: function (arg) {
      // code show modal
      const app = this.$refs.APPR;
      app.show = true;
      app.loadTurn(arg.event.id);
    },
    isSelectable(item) {
      return item.status === "PENDIENTE" || item.status === "NO APROBADO" || item.status === "PENDIENTE POR AUTORIZACIÓN";
    },
    isSelectAll(dataGrid) {
      let items = [];
      dataGrid
        .getDataSource()
        .store()
        .load()
        .done(function (data) {
          items = data;
        });
      let selectableItems = items.filter(this.isSelectable);
      let selectedRowKeys = dataGrid.option("selectedRowKeys");

      if (!selectedRowKeys.length) {
        return false;
      }
      return selectedRowKeys.length >= selectableItems.length
        ? true
        : undefined;
    },

    onEditorPreparing(e) {
      let dataGrid = e.component;
      if (e.command === "select") {
        if (e.parentType === "dataRow" && e.row) {
          if (!this.isSelectable(e.row.data)) {
            e.editorOptions.disabled = true;
          }
        } else if (e.parentType === "headerRow") {
          e.editorOptions.onInitialized = (e) => {
            selectAllCheckBox = e.component;
          };
          e.editorOptions.value = this.isSelectAll(dataGrid);
          e.editorOptions.onValueChanged = (e) => {
            if (!e.event) {
              if (e.previousValue && !checkBoxUpdating) {
                e.component.option("value", e.previousValue);
              }
              return;
            }
            if (this.isSelectAll(dataGrid) === e.value) {
              return;
            }
            e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
            e.event.preventDefault();
          };
        }
      }
    },

    onSelectionChanged(e) {
      if (!e.selectionChangedBySelectBox) {
        this.prefix = null;
      }

      const [data] = e.selectedRowsData;
      if (data && data.estado.id) {
        this.selectedRowsData = e.selectedRowsData.map((item) => item.id);
      } else {
        this.selectedRowsData = [];
      }
      let deselectRowKeys = [];
      e.selectedRowsData.forEach((item) => {
        if (!this.isSelectable(item)) {
          deselectRowKeys.push(e.component.keyOf(item));
        }
      });
      if (deselectRowKeys.length) {
        e.component.deselectRows(deselectRowKeys);
      }
      checkBoxUpdating = true;
      selectAllCheckBox.option("value", this.isSelectAll(e.component));
      checkBoxUpdating = false;
    },
    approbation(newStatus) {

      const idx = this.selectedRowsData.map((el) => el);

      let data = {
        ids: idx,
        estado_id: newStatus,
        user: this.user.usuario,
      };

      axios
        .post("/turns/approvals", data)
        .then((res) => {
          if (res.data.message == "success") {
            toastr.success(res.data.information);
            this.loadTurns();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteTurn(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar el turno?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/turns/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadTurns();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    loadTurns() {
      this.selectedRowsData = [];
      this.items = [];
      this.loading = true;
      var item = [];
      var stat = [];
      axios
        .get("/turns")
        .then((res) => {
          this.items = res.data;
          this.calendarOptions.events = [];
          res.data.map((el) => {
            stat.push(el.estado);
            item.push(el.contrata);
            // let range = el.observacion.split("|");
            el.status = el.estado.estado;
            this.calendarOptions.events.push({
              id: el.id,
              title: `${el.estado.estado
                .charAt(0)
                .toUpperCase()} - ${el.observacion
                .split("-")
                .join(" y ")
                .split("|")
                .join(" a ")} - ${el.contrata} `,
              // date: el.fecha,
              start: `${el.fecha.replace(" ", "T")}`,
              end: `${el.fecha_fin.replace(" ", "T")}`,
              backgroundColor: el.jornada ? el.jornada.color : "#009CDE",
              borderColor: el.jornada ? el.jornada.color : "#009CDE",
              eventBackgroundColor: "#378006",
              contrata: el.contrata,
              estado: el.estado.id,
            });
            el.estado_id = el.estado.id;

            // console.table(this.calendarOptions.events);
          });
          this.allCalendar = this.calendarOptions.events;
          this.estados = _.uniqBy(stat, "id");
          this.contratas = _.uniq(item);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    deleteChosesTurns(ids) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar los turnos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(`/turns/destroy-masivo`, { ids: ids })
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadTurns();
                } else {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "warning"
                  );
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Document.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>
