<template>
  <b-modal
    title="Cargar turnos"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col>
        <div class="form-group">
          <label>Código</label>
          <input
            v-model="form.cod"
            type="text"
            class="form-control form-control-sm"
            placeholder="Código"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Recurso</label>
          <input
            v-model="form.placa"
            type="text"
            max="6"
            class="form-control form-control-sm"
            placeholder="Placa"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Observación</label>
          <input
            v-model="form.descripcion"
            type="text"
            class="form-control form-control-sm"
            placeholder="Example: 14:00 a 22:00"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <label>Jornada</label>
        <select class="form-control form-control-sm" v-model="form.tipo">
          <option :value="null">Seleccione</option>
          <option :value="item.id" v-for="(item, idx) in journey" :key="idx">
            {{ item.nombre }}
          </option>
        </select>
      </b-col>
      <b-col>
        <div class="form-group">
          <label>Fecha inicio</label>
          <input
            v-model="form.fecha"
            type="date"
            class="form-control form-control-sm"
            placeholder="Fecha"
          />
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
          <label>Fecha fin</label>
          <input
            v-model="form.fecha_fin"
            :min="form.fecha"
            type="date"
            class="form-control form-control-sm"
            placeholder="Fecha fin"
          />
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="createTurn()">
          <i class="la la-save"></i>
          {{ loading ? "Cargando..." : "Cargar turno" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      show: false,
      loading: false,
      journey: [],
      form: {
        cod: null,
        placa: null,
        descripcion: null,
        fecha: null,
        fecha_fin: null,
        tipo: null,
      },
    };
  },
  mounted() {
    this.loadJourn();
  },
  methods: {
    init() {
      this.show = true;
      this.form = {
        cod: null,
        placa: null,
        descripcion: null,
        fecha: null,
        fecha_fin: null,
        tipo: null,
      };
    },
    loadJourn() {
      axios
        .get(`/tipo-jornadas`)
        .then((res) => {
          this.journey = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    validationDescription(description) {
      const descripcion = description.split("|");
      if (descripcion.length == 2) {
        const [init, end] = descripcion;
        if (init.length && end.length) {
          if (init.split(":").length > 1 && end.split(":").length > 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }

      // return false
    },
    createTurn() {
      if (this.form.descripcion && this.form.fecha) {
        // this.loading = true;
        this.form.descripcion = this.form.descripcion
          .split(" y ")
          .join("-")
          .split(" a ")
          .join("|");
        if (this.validationDescription(this.form.descripcion)) {
          const data = [this.form];
          axios
            .post("/turns", { data: data })
            .then((res) => {
              if (res.data.message == "success") {
                this.$emit("loadTurns", true);
                toastr.success(res.data.information);
                this.show = false;
              } else {
                toastr.info(res.data.information);
              }
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
        } else {
          toastr.info("asegurese de digitar de forma correcta la descripción");
        }
      } else {
        if (!this.form.descripcion) {
          toastr.info("La observación es obligatoria");
        }
        if (!this.form.fecha) {
          toastr.info("La fecha es obligatoria");
        }
      }
    },
  },
};
</script>
